.category-details-block{
    height: 53%;
}

.add-category-modal-content {
    display: flex;
    flex-direction: column;
}
.category-name-container {
    display: flex;
    flex-direction: column;
    width: 50% !important;
    margin-top: 1%;
}
.category-name-container input{
    border: 1px solid #bbbb;
    border-radius: 1px;
    box-shadow: 0px 0px 4px #00000040;
    margin: 0;
}

.restrict-groups-checkbox{
    margin-top: 2%;
    justify-content: space-evenly;
    width: 100%;
    align-items: flex-start !important;
    color: #000000;
}
.category-group-management-container{
    margin-top: 0 !important;
}
.restrict-groups-checkbox-txt{
    width: 70%;
    font-weight: lighter;
    color: #606060;
}

.category-cards-title{
    margin-top: 0 !important;
}
.group-management-card-container {
    display: flex;
}

.category-filter-container{
    width: 53% !important;
}

.category-container{
     height: 125%;
     display: flex;
     flex-direction: column;
     padding-left: 1px;
}

input[type="file"]::file-selector-button {
    background-color: #448CCB;
    color: white;
    border: none;
    height: 30px;
}

.file-upload-ctr{
    width: 50%;
}

.file-upload-ctr input{
    border: 1px solid #bbbb;
    width: 85%;
    border-radius: 1px;
    margin-top: 6%;
    box-shadow: 0px 0px 4px #00000040;
}

.preview-header{
    display: flex;
    margin-bottom: 1%;
    align-items: center;
}
.left-element{
    flex: 1;
    text-align: left;
    letter-spacing: 0.5px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    line-clamp: 1;
    word-break: break-word;
    -webkit-box-orient: vertical;
    width: -11%;
    overflow: hidden;
}
.right-element{
    flex: 1 1;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    gap: 7px;
}
.right-element img{
    height: 30px;
}
.right-element button{
    all: unset;
    cursor: pointer;
}
.middle-element{
    display: flex;
    gap: 5px;
    background-color: #ffffff;
    border-radius: 2%;
    box-shadow: 0px 0px 4px #00000040
}
.download-icon{
    cursor: pointer;
    filter: brightness(0) invert(0);
    height: 20px !important;
}
.image-preview{
    width: 100%;
    height: 90%;
    padding: 2%;
    overflow: auto;
}
.image-preview img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.image-preview iframe{
    width: 100%;
    height: 100%;
    border: none;
    background-color: white;
    overflow: hidden;
    object-fit: contain;
}
.rpv-core__inner-pages{
    overflow: auto;
    scrollbar-width: "none";
    -ms-overflow-style: "none";
}
.file-preview-model {
    overflow: hidden;
}
.rpv-core__inner-pages::-webkit-scrollbar {
        display: none;
}
#label-name{
    font-size: 13px !important;
    letter-spacing: 0.5px !important;
}
.zoom-controls p{
    font-size:13px;
    font-weight: bold;
    background-color: #ffffff;
    color: #000000;
    padding: 3px 3px;
    border-radius: 3px
}
.zoom-controls{
    display: flex;
    gap: 7px;
    align-items: center;
    height: 30px;
}
.zoom-controls button{
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #000000;
    font-size: 20px;
    border: none;
    cursor: pointer;
}
.add-category-modal{
    z-index: 1;
}
@media (min-width: 2100px){   
    .add-category-modal{
        height: 65%;
        width: 50%;
    }
    .category-button-container{
        margin-top: 2%
    }
    .category-details-block{
        height: auto;
    }
    .category-modal-title{
        height: auto;
    }
}

@media (min-width: 1440px) and (max-width:2000px) {
    .category-details-block{
        height: auto;
    }
    .category-modal-title{
        height: auto !important;
    }
}

@media (max-width: 330px) {
    .category-details-block{
        height: 20%;
    }
    .category-container{
        height: 230%;
    }   
}

@media (min-width: 330px) and (max-width: 640px) {
    .category-container{
        height: 930px;
    }
    .category-details-block {
        height: auto;
    }
    .category-name-container{
        width: 100% !important;
    }
    .category-name-container input{
        width: 98%
    }
    .category-filter-container{
        width: 100% !important;
    }
    .category-dropDown{
        width: 98% !important;
    }
    .response-container {
        margin-top: 3%;
    }
    .file-upload-ctr{
        width: 100%;
    }
    .file-upload-ctr input{
        width: 98%;
    }
}

@media (min-width: 640px) and (max-width: 1400px) {
    .category-container {
        height: 600px;
    }
    .category-details-block {
        height: auto;
    }
}

@media (min-width: 760px) and (max-width: 1030px) and (min-height: 800px) and (max-height: 1400px){
    .add-category-modal {
        height:auto;
    }
    .category-details-block {
        height: auto;
    }
    .category-container {
        height: 565px;
    }
}
